import { NgModule }      from '@angular/core';

import { InfiniteScroll }  from './infinite-scroll';
import { AxisResolverFactory } from './axis-resolver';
import { PositionResolverFactory } from './position-resolver';


export class InfiniteScrollModule { static decorators: DecoratorInvocation[] = [
{ type: NgModule, args: [{
  imports:      [  ],
  declarations: [ InfiniteScroll ],
  exports:      [ InfiniteScroll ],
  providers:    [ AxisResolverFactory, PositionResolverFactory ]
}, ] },
];
/** @nocollapse */
static ctorParameters: ({type: any, decorators?: DecoratorInvocation[]}|null)[] = [
];
}

interface DecoratorInvocation {
  type: Function;
  args?: any[];
}
