import { Directive, ElementRef, Input, Output, EventEmitter, OnDestroy, OnInit, OnChanges, SimpleChanges, NgZone } from '@angular/core';
import { Scroller, InfiniteScrollEvent } from './scroller';
import { PositionResolverFactory } from './position-resolver';


export class InfiniteScroll implements OnDestroy, OnInit, OnChanges {
  public scroller: Scroller;

   _distanceDown: number = 2;
   _distanceUp: number = 1.5;
   _throttle: number = 300;
   _disabled: boolean = false;
   scrollWindow: boolean = true;
   _immediate: boolean = false;
   _horizontal: boolean = false;
   _alwaysCallback: boolean = false;

   scrolled = new EventEmitter<InfiniteScrollEvent>();
   scrolledUp = new EventEmitter<InfiniteScrollEvent>();

  constructor(
    private element: ElementRef,
    private zone: NgZone,
    private positionResolverFactory: PositionResolverFactory
  ) {}

  ngOnInit() {
    if (typeof window !== 'undefined') {
      const containerElement = this.scrollWindow ? window : this.element;
      this.scroller = new Scroller(containerElement, setInterval, this.element,
          this.onScrollDown.bind(this), this.onScrollUp.bind(this),
          this._distanceDown, this._distanceUp, {}, this._throttle,
          this._immediate, this._horizontal, this._alwaysCallback,
          this._disabled, this.positionResolverFactory);
    }
  }

  ngOnDestroy () {
    if (this.scroller) {
      this.scroller.clean();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes['_disabled'] && this.scroller){
      this.scroller.handleInfiniteScrollDisabled(changes['_disabled'].currentValue);
    }
  }

  onScrollDown(data: InfiniteScrollEvent = { currentScrollPosition: 0 }) {
    this.zone.run(() => this.scrolled.next(data));
  }

  onScrollUp(data: InfiniteScrollEvent = { currentScrollPosition: 0 }) {
    this.zone.run(() => this.scrolledUp.next(data));
  }
static decorators: DecoratorInvocation[] = [
{ type: Directive, args: [{
  selector: '[infinite-scroll]'
}, ] },
];
/** @nocollapse */
static ctorParameters: ({type: any, decorators?: DecoratorInvocation[]}|null)[] = [
{type: ElementRef, },
{type: NgZone, },
{type: PositionResolverFactory, },
];
static propDecorators: {[key: string]: DecoratorInvocation[]} = {
'_distanceDown': [{ type: Input, args: ['infiniteScrollDistance', ] },],
'_distanceUp': [{ type: Input, args: ['infiniteScrollUpDistance', ] },],
'_throttle': [{ type: Input, args: ['infiniteScrollThrottle', ] },],
'_disabled': [{ type: Input, args: ['infiniteScrollDisabled', ] },],
'scrollWindow': [{ type: Input, args: ['scrollWindow', ] },],
'_immediate': [{ type: Input, args: ['immediateCheck', ] },],
'_horizontal': [{ type: Input, args: ['horizontal', ] },],
'_alwaysCallback': [{ type: Input, args: ['alwaysCallback', ] },],
'scrolled': [{ type: Output },],
'scrolledUp': [{ type: Output },],
};
}

interface DecoratorInvocation {
  type: Function;
  args?: any[];
}
